.container {
  width: 100%;
  max-width: 1580px;
  margin: 0 auto;
  padding: 0 15px;
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'europa', sans-serif;
}

* {
  box-sizing: border-box !important;
  max-width: 100%;
  // outline: 1px dashed blue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #000;
}

/* CSS Variables */
:root {
  --covid-black: #000;
  --covid-gray: #808291;
  --covid-white: #fff;
  --covid-pink: #f8d8de;
  --covid-red: #ff123f;
}

.color-red {
  color: var(--covid-red);
}

.color-red {
  color: var(--covid-red);
}

/* Layout stuff */
.nodos-pretitle {
  color: var(--covid-red);
  text-transform: uppercase;
  font-size: 20px;
}

.nodos-title {
  color: var(--covid-black);
  font-size: 30px;
  font-weight: bold;
}

.medium-component {
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @media screen and (min-width: 768px) {
      flex: 0 0 50%;
    }

    @media screen and (min-width: 1200px) {
      flex: 0 0 25%;
      padding: 40px 0;
    }
  }
}
