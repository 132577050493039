.footer {
  padding: 10px 0;
  text-align: center;

  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }

  &__cc {
    flex: 0 0 40%;
    text-align: center;
  }
}
